<template>
  <div id="simple-calendar-app">
    <div class="vx-card no-scroll-content">
      <calendar-view
        ref="calendar"
        :displayPeriodUom="calendarView"
        eventTop="3.5rem"
        eventBorderHeight="2px"
        class="theme-default"
        @click-event="opengBookingPopup"
        :show-date="showDate"
        :events="calendarItems"
        :showTimes="true"
      >
        <div slot="header" class="mb-4">
          <div class="vx-row no-gutter">
            <!-- Month Name -->
            <div class="vx-col w-1/3 items-center sm:flex hidden">
              <!-- Add new event button -->
              <!-- <vs-button
                icon-pack="feather"
                icon="icon-plus"
                @click="promptAddNewEvent(new Date())"
                >Add</vs-button
              > -->
            </div>

            <!-- Current Month -->
            <div
              class="
                vx-col
                sm:w-1/3
                w-full
                sm:my-0
                my-3
                flex
                sm:justify-end
                justify-center
                order-last
              "
            >
              <div class="flex items-center">
                <feather-icon
                  :icon="$vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
                  @click="updateMonth(-1)"
                  svgClasses="w-5 h-5 m-1"
                  class="cursor-pointer bg-primary text-white rounded-full"
                />

                <span class="mx-3 text-xl font-medium whitespace-no-wrap">{{
                  showDate | month
                }}</span>

                <feather-icon
                  :icon="$vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
                  @click="updateMonth(1)"
                  svgClasses="w-5 h-5 m-1"
                  class="cursor-pointer bg-primary text-white rounded-full"
                />
              </div>
            </div>

            <div class="vx-col sm:w-1/3 w-full flex justify-center">
              <template v-for="(view, index) in calendarViewTypes">
                <vs-button
                  v-if="calendarView === view.val"
                  :key="String(view.val) + 'filled'"
                  type="filled"
                  class="p-3 md:px-8 md:py-3"
                  :class="{
                    'border-l-0 rounded-l-none': index,
                    'rounded-r-none': calendarViewTypes.length !== index + 1,
                  }"
                  @click="calendarView = view.val"
                  >{{ view.label }}</vs-button
                >
                <vs-button
                  v-else
                  :key="String(view.val) + 'border'"
                  type="border"
                  class="p-3 md:px-8 md:py-3"
                  :class="{
                    'border-l-0 rounded-l-none': index,
                    'rounded-r-none': calendarViewTypes.length !== index + 1,
                  }"
                  @click="calendarView = view.val"
                  >{{ view.label }}</vs-button
                >

                
              </template>
            </div>
          </div>
           <div class="vx-row sm:flex hidden mt-4">
            <div class="vx-col w-full flex">
              <!-- Labels -->
              <div class="flex flex-wrap sm:justify-start justify-center">
                <div
                  v-for="(label, index) in calendarLabels"
                  :key="index"
                  class="flex items-center mr-4 mb-2"
                >
                  <div
                    class="h-3 w-3 inline-block rounded-full mr-2"
                    :class="'bg-' + label.color"
                  ></div>
                  <span>{{ label.text }}</span>
                  
                </div>
              </div>
              
            </div>
          </div>

        </div>
      </calendar-view>
    </div>

    <!-- Educator / User details -->
    <booking-popup
      ref="bookingPopupRef"
      @bookingUpdated="fetchBookingData"
      @videoSessionCreated="toggleSessionCard"
    />
  </div>
</template>

<script>


import { API, graphqlOperation } from "@aws-amplify/api";
import {
  educatorByUser,
  educatorServicesByEducatorId,
  sessionBookingByServiceId,
  sessionBookingByUser,
  getUser,
  getEducatorServicePackage,
  getEducatorService,
} from "@/graphql/queries";

import BookingPopup from "./BookingPopup.vue";
import SessionCard from "../../SessionCard.vue";



export default {
  name: "SimpleCalendar",
  components: {
    CalendarView,
    CalendarViewHeader,
    Datepicker,
    BookingPopup,
    SessionCard,
  },
  data() {
    return {
      activeSessionCard: false,
      zoomInviteActive: false,
      zmSignature: "",
      zmPassword: "",
      zmSessionName: "",
      booking: [],
      services: [],
      showDate: new Date(),
      labelLocal: "none",
      langHe: he,
      langEn: en,
      calendarView: "month",
      calendarViewTypes: [
        {
          label: "Month",
          val: "month",
        },
        {
          label: "Week",
          val: "week",
        },
        {
          label: "Year",
          val: "year",
        },
      ],
    };
  },
  computed: {
    labelColor() {
      return (label) => {
        if (label === "business") return "success";
        else if (label === "work") return "warning";
        else if (label === "personal") return "danger";
        else if (label === "none") return "primary";
      };
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
     calendarLabels() {
      var data = [
        {
          text: "Confirmed",
          value: "confirmed",
          color: "success"
        },
        {
          text: "Pending confirmation",
          value: "pending",
          color: "warning"
        },
        {
          text: "Cancelled",
          value: "rejected",
          color: "primary"
        },
       
      ];

      return data;
    },
    calendarItems() {
      return this.booking.map((item, index) => {
        let classes = [];
        if(item.isAccepted === true) {
          classes = "bg-success rounded";
        }else if(item.isAccepted === false) {
          classes = "bg-primary rounded";
        }else {
          classes = "bg-warning rounded";
        }        
        return {
          id: item.id,
          startDate: new Date(item.dates[0]),
          title: item.serviceRef.title,
          classes,
          index,
        };
      });
    },
  },
  methods: {
    updateMonth(val) {
      this.showDate = this.$refs.calendar.getIncrementedPeriod(val);
    },
    async opengBookingPopup(event) {
      try {
        const popup = this.$refs.bookingPopupRef;
        const booking = this.booking[event.originalEvent.index];
        const getUserRes = await API.graphql(
          graphqlOperation(getUser, {
            name: booking.user,
          })
        );
        if (!getUserRes.data.getUser) {
          this.$vs.notify(createErrorObject("Error", "Cannot find User data"));
          return;
        }
        popup.displayName = getUserRes.data.getUser.displayName;
        popup.date = booking.dates[0];
        popup.timeslots = booking.timeslots;
        popup.purpose = booking.purpose;
        popup.message = booking.message;
        popup.id = booking.id;
        // get package data
        const packageRes = await API.graphql(
          graphqlOperation(getEducatorServicePackage, {
            id: booking.packageID,
          })
        );

        const service = this.services.filter(
          (sv) => sv.id === booking.educatorServiceID
        )[0];
        popup.service = service;
        if (!packageRes.data.getEducatorServicePackage) {
          this.$vs.notify(
            createErrorObject("Error", "Cannot find package data")
          );
          return;
        }
        popup.package = packageRes.data.getEducatorServicePackage;
        popup.active = true;
        this.$refs.bookingPopupRef.active = true;
      } catch (err) {
        console.log(err);
      }
    },
    async fetchBookingData() {
      try {
          this.$vs.loading()
        if (this.$store.state.auth.userData.isPartner) {
          const getEducator = await API.graphql(
            graphqlOperation(educatorByUser, {
              user: this.$store.state.auth.userData.name,
            })
          );
          if (!getEducator.data.educatorByUser) {
            this.$vs.notify(
              createErrorObject("Error", "Cannot find educator data")
            );
            return;
          }

          const getService = await API.graphql(
            graphqlOperation(educatorServicesByEducatorId, {
              educatorID: getEducator.data.educatorByUser.items[0].id,
            })
          );

          if (!getService.data.educatorServicesByEducatorID.items) {
            this.$vs.notify(
              createErrorObject("Error", "Cannot find educator services data")
            );
            return;
          }

          this.services = getService.data.educatorServicesByEducatorID.items;
          let bookingList = [];
          for (const service of getService.data.educatorServicesByEducatorID
            .items) {
            const booking = await API.graphql(
              graphqlOperation(sessionBookingByServiceId, {
                educatorServiceID: service.id,
              })
            );
            bookingList.push(...booking.data.sessionBookingByServiceID.items);
          }
          this.booking = bookingList;
        } else {
          const booking = await API.graphql(
            graphqlOperation(sessionBookingByUser, {
              user: this.$store.state.auth.userData.name,
            })
          );
          this.booking = booking.data.sessionBookingByUser.items;
          let services = [];
          for (const bk of booking.data.sessionBookingByUser.items) {
            const service = await API.graphql(
              graphqlOperation(getEducatorService, {
                id: bk.educatorServiceID,
              })
            );
            services.push(service.data.getEducatorService);
          }
          this.services = services;
        }
          this.$vs.loading.close()
      } catch (err) {
        console.log(err);
      }
    },

    toggleSessionCard(id) {
      this.$sessionCard.id = id;
      this.$sessionCard.toggle();
    },
  },
  mounted() {
    this.$store.commit("TOGGLE_REDUCE_BUTTON", true);
  },
  created() {
    this.fetchBookingData();
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/simple-calendar.scss";

.cv-day {
  display: unset !important;
}
// .cv-event:not(:first-child, :last-child){
//   top: calc(0em + -2px + 2.5rem) !important;
// }
.cv-event {
  padding-top: 2px;
  padding-bottom: 2px;
}

@media only screen and (max-width: 600px) {
#content-area .content-wrapper {
  // min-height: calc(var(--vh, 1vh) * 100 - 0rem);
  //  min-height:unset !important;
}
}
</style>
