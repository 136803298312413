var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"simple-calendar-app"}},[_c('div',{staticClass:"vx-card no-scroll-content"},[_c('calendar-view',{ref:"calendar",staticClass:"theme-default",attrs:{"displayPeriodUom":_vm.calendarView,"eventTop":"3.5rem","eventBorderHeight":"2px","show-date":_vm.showDate,"events":_vm.calendarItems,"showTimes":true},on:{"click-event":_vm.opengBookingPopup}},[_c('div',{staticClass:"mb-4",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"vx-row no-gutter"},[_c('div',{staticClass:"vx-col w-1/3 items-center sm:flex hidden"}),_c('div',{staticClass:"vx-col sm:w-1/3 w-full sm:my-0 my-3 flex sm:justify-end justify-center order-last"},[_c('div',{staticClass:"flex items-center"},[_c('feather-icon',{staticClass:"cursor-pointer bg-primary text-white rounded-full",attrs:{"icon":_vm.$vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon',"svgClasses":"w-5 h-5 m-1"},on:{"click":function($event){return _vm.updateMonth(-1)}}}),_c('span',{staticClass:"mx-3 text-xl font-medium whitespace-no-wrap"},[_vm._v(_vm._s(_vm._f("month")(_vm.showDate)))]),_c('feather-icon',{staticClass:"cursor-pointer bg-primary text-white rounded-full",attrs:{"icon":_vm.$vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon',"svgClasses":"w-5 h-5 m-1"},on:{"click":function($event){return _vm.updateMonth(1)}}})],1)]),_c('div',{staticClass:"vx-col sm:w-1/3 w-full flex justify-center"},[_vm._l((_vm.calendarViewTypes),function(view,index){return [(_vm.calendarView === view.val)?_c('vs-button',{key:String(view.val) + 'filled',staticClass:"p-3 md:px-8 md:py-3",class:{
                  'border-l-0 rounded-l-none': index,
                  'rounded-r-none': _vm.calendarViewTypes.length !== index + 1,
                },attrs:{"type":"filled"},on:{"click":function($event){_vm.calendarView = view.val}}},[_vm._v(_vm._s(view.label))]):_c('vs-button',{key:String(view.val) + 'border',staticClass:"p-3 md:px-8 md:py-3",class:{
                  'border-l-0 rounded-l-none': index,
                  'rounded-r-none': _vm.calendarViewTypes.length !== index + 1,
                },attrs:{"type":"border"},on:{"click":function($event){_vm.calendarView = view.val}}},[_vm._v(_vm._s(view.label))])]})],2)]),_c('div',{staticClass:"vx-row sm:flex hidden mt-4"},[_c('div',{staticClass:"vx-col w-full flex"},[_c('div',{staticClass:"flex flex-wrap sm:justify-start justify-center"},_vm._l((_vm.calendarLabels),function(label,index){return _c('div',{key:index,staticClass:"flex items-center mr-4 mb-2"},[_c('div',{staticClass:"h-3 w-3 inline-block rounded-full mr-2",class:'bg-' + label.color}),_c('span',[_vm._v(_vm._s(label.text))])])}),0)])])])])],1),_c('booking-popup',{ref:"bookingPopupRef",on:{"bookingUpdated":_vm.fetchBookingData,"videoSessionCreated":_vm.toggleSessionCard}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }