<template>
  <vs-popup
    class="calendar-event-dialog"
    title="Learning session details"
    :active.sync="active"
  >
    <div class="px-4 py-2">
      <div class="calendar__label-container flex"></div>

      <div class="" v-if="isPartner">
        <h6 class="mb-1 font-semibold">User name:</h6>
        <p>{{ displayName }}</p>
      </div>

      <div class="" v-else>
        <h6 class="mb-1 font-semibold">Educator name:</h6>
        <a class="text-primary cursor-pointer" @click="goToProfile">{{ educator }}</a>
      </div>

      <div class="mt-5">
        <h6 class="mb-1 font-semibold">Purpose of session:</h6>
        <p>{{ purpose }}</p>
      </div>

      <div class="mt-5">
        <h6 class="mb-1 font-semibold">Service requested:</h6>
        <p>{{ service.title }}</p>
      </div>

      <div class="mt-5">
        <h6 class="mb-1 font-semibold">Topics:</h6>
        <p v-if="service.subjects">{{ service.subjects.toString() }}</p>
      </div>

      <div class="mt-5">
        <h6 class="mb-1 font-semibold">Date:</h6>
        <p>{{ dateFormated(date) }}</p>
      </div>
       <div class="mt-3">
        <h6 class="mb-1 font-semibold">Time slots:</h6>      
        <div v-for="(timeslot, index) in timeslots" :key="index" >
            <h8>{{ timeslot}}</h8>
        </div>
      </div>

      <div class="mt-5">
        <h6 class="mb-1 font-semibold">Message:</h6>
        <p>{{ message }}</p>
      </div>

      <!-- <div class="mt-5" v-if="isPartner">
        <label class="font-bold">Add Session Password</label>
        <vs-input size="small" v-model="password" type="password" />
      </div> -->
    </div>

    <div class="float-right mt-3" v-if="isPartner">
      <vs-button class="mr-2" color="Blue" @click="generateZoomToken">Start Session</vs-button>
      <vs-button class="mr-2" color="success" @click="updateBooking(true)">Accept</vs-button>
      <vs-button class="mt-2 md:mt-0" color="danger" @click="updateBooking(false)">Reject</vs-button>
    </div>

    <div class="float-right" v-else>
      <!-- <vs-button class="mr-2" type="flat" @click="editSessionBooking">Edit details</vs-button> -->
      <vs-button @click="updateBooking(false)">Cancel session</vs-button>
    </div>
  </vs-popup>
</template>

<script>
import { KJUR } from 'jsrsasign'
import { API, graphqlOperation } from '@aws-amplify/api'
import { updateEducatorSessionBooking } from '@/graphql/mutations'
import { createErrorObject, createSuccessObject } from '@/utils'
import axios from 'axios'

export default {
  name: "BookingPopup",
  data() {
    return {
      active: false,
      displayName: '',
      date: '',
      timeslots: [],
      purpose: '',
      message: '',
      package: '',
      service: '',
      id: '',
      password: '',
    };
  },
  computed: {
    isPartner () {
      return this.$store.state.auth.userData.isPartner
    },
    educator () {
      return this.service.educatorRef.user
    }
  },
  methods: {
    dateFormated (date_utc){
      var date = new Date(date_utc);
      return date.toLocaleDateString();
    },
    async updateBooking (isAccepted) {
      try {
        const updateRes = await API.graphql(graphqlOperation(updateEducatorSessionBooking, {
          input: {
            id: this.id,
            isAccepted
          }
        }))
        this.$vs.notify(createSuccessObject('Success', 'Booking status updated'))
        this.active = false
        this.$emit('bookingUpdated')
      } catch (err) {
        this.$vs.notify(createErrorObject('Error', 'Cannot update Booking status'))
        console.log(err)
      }
    },
    async generateZoomToken () {
      try {
        this.$vs.loading()
        // if (this.password === '') {
        //   this.$vs.notify(createErrorObject('Error', 'Session password field is empty'))
        //   return false
        // }

        // if (this.password.length > 6) {
        //   this.$vs.notify(createErrorObject('Error', 'Password length must not be longer than 6'))
        //   return false
        // }

        let signature = "";
        // try {
        const iat = Math.round(new Date().getTime() / 1000);
        const exp = iat + 60 * 60 * 2;
  
        // Header
        const oHeader = { alg: "HS256", typ: "JWT" };
        // Payload
        const oPayload = {
          app_key: process.env.VUE_APP_ZOOM_SDK_KEY,
          iat,
          exp,
          tpc: this.service.title,
          pwd: this.password,
        };
        // Sign JWT
        const sHeader = JSON.stringify(oHeader);
        const sPayload = JSON.stringify(oPayload);
        signature = KJUR.jws.JWS.sign("HS256", sHeader, sPayload, process.env.VUE_APP_ZOOM_SDK_SECRET);
        
        const updateRes = await API.graphql(graphqlOperation(updateEducatorSessionBooking, {
          input: {
            id: this.id,
            zoomSignature: signature,
            zoomPassword: this.password,
            isOnline: true
          }
        }))

        await this.$zmClient.join(this.service.title, signature, this.$store.state.auth.userData.displayName, this.password);
        this.sendWelcomeEmail(updateRes.data.updateEducatorSessionBooking.userRef);
        this.$vs.notify(createSuccessObject('Success', 'Zoom session created'))
        this.$vs.loading.close()
        this.$router.push({name: 'video-session', params: {
           sessionId: this.id
        }})
        // this.$emit('videoSessionCreated', this.id)
        this.active = false
      } catch (err) {
        console.log(err)
      }
    },
    async sendWelcomeEmail(userData){
      let url = `${process.env.VUE_APP_BASE_URL}/api/sendAppMail`;
      //  let url = "http://localhost:7000/api/sendAppMail";
      axios.post(url, { 
        template_code: 'Session_started',
        to: userData.email,
        bcc: 'support@coursepal.app',
        username: userData.displayName, 
        educator: this.$store.state.auth.userData.displayName, 
      })
      .then(res => 
        console.log(res)
      )
    },
    goToProfile () {
      this.$router.push(`partner/${this.educator}`)
    }
  }
};
</script>