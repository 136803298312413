<template>
  <div class="session-card">
    <vs-card v-if="active" fixedHeight > 
      <div>
      <h5>{{ session.topic }}</h5>
      <div class="flex justify-between">
        <vs-button size="small" @click="goToVideo" color="success">Go To Video</vs-button>
        <vs-button size="small" @click="leave">Leave Session</vs-button>
      </div>
      </div> 
    </vs-card>
  </div>
</template>

<script>
import { API, graphqlOperation } from '@aws-amplify/api'
import { updateEducatorSessionBooking } from '@/graphql/mutations'

export default {
  name: 'SessionCard',
  data () {
    return {
      id: '',
      session: '',
      active: false
    }
  },
  methods: {
    // async toggle() {
    //   await this.setSessionData()
    //   this.active = true
    // },
    // async setSessionData () {
    //   const session = await this.$zmClient.getSessionInfo()
    //   this.session = session
    // },
    goToVideo () {
      this.$router.push({ name: 'video-session', params: {
        sessionId: this.id
      } })
      this.active = false
    },
    async leave () {
      try {
        this.$zmClient.leave(true)
        await API.graphql(graphqlOperation(updateEducatorSessionBooking, {
          input: {
            id: this.id,
            isOnline: false
          }
        }))
        this.active = false
      } catch (err) {
        console.log(err)
      }
    }
  },
  // created () {
  //   this.setSessionData()
  // }
}
</script>

<style scoped>
.session-card {
  position: sticky;
  position: -webkit-sticky;
  bottom: 3rem;
  left: calc(100% - 22rem);
  width: 20rem;
  z-index: 52000;
}
</style>